// extracted by mini-css-extract-plugin
export var classic = "missing-pets-module--classic--dc1d8";
export var contactButton = "missing-pets-module--contactButton--93bb2";
export var fadeIn = "missing-pets-module--fade-in--d3477";
export var iconMarker = "missing-pets-module--iconMarker--a7194";
export var item = "missing-pets-module--item--6765c";
export var leftSide = "missing-pets-module--leftSide--fb431";
export var list = "missing-pets-module--list--eb651";
export var marquee = "missing-pets-module--marquee--44d2d";
export var missingDate = "missing-pets-module--missingDate--58fb5";
export var missingRibbon = "missing-pets-module--missingRibbon--37759";
export var name = "missing-pets-module--name--50dc0";
export var notFound = "missing-pets-module--notFound--b7026";
export var photo = "missing-pets-module--photo--9ae90";
export var regency = "missing-pets-module--regency--04b8c";
export var rightSide = "missing-pets-module--rightSide--30040";
export var section = "missing-pets-module--section--35a81";
export var text = "missing-pets-module--text--7af42";
export var verifiedBadge = "missing-pets-module--verifiedBadge--684d2";
export var whiteVerifiedBadge = "missing-pets-module--whiteVerifiedBadge--4087a";