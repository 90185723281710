import React, { useCallback, useRef } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Button, Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

import * as css from "./missing-pets.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import metaPreviewImage from "../assets/images/thumbnails/thumbnail_index.jpg"
import MetaTags from "../components/MetaTags"
import log from "../components/log"
import axios from "../api/axios"
import moment from "moment"
import Moment from "react-moment"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { Link } from "@gatsbyjs/reach-router"
import NotFoundIllustration from "../assets/images/not_found.webp"
import { LoadingPageOnly } from "../components/auth/LoadingPage"
import LoadingDefault from "../components/missing-pets/LoadingDefault"

// markup
const MissingPets = () => {

    const [isGettingLoading, setIsGettingLoading] = React.useState(true)
    const [isGettingDataReady, setIsGettingDataReady] = React.useState(false)
    const [isGettingDataFailed, setIsGettingDataFailed] = React.useState(false)
    const [gettingData, setGettingData] = React.useState([])


    React.useEffect(() => {
        load()
    }, [])

    function load() {

        let endpoint = '/api/public/lost_pets'
        log('debug', 'Calling API', {
            endpoint: endpoint
        })

        const get = async () => {
            setIsGettingLoading(true)
            setIsGettingDataReady(false)

            try {
                const res = await axios.get(endpoint)

                setIsGettingDataFailed(false)
                setGettingData(res.data.data.list)
                setIsGettingLoading(false)
                setIsGettingDataReady(true)
            } catch (err: any) {
                if (err?.response == undefined) {

                    log("error", 'Tidak dapat menghubungi server, coba lagi.', {
                        endpoint: endpoint,
                        error: err,
                    })
                } else {

                    log("error", err?.response?.data?.message, {
                        endpoint: endpoint,
                        error: err,
                    })
                }
                setIsGettingDataFailed(true)
                setIsGettingDataReady(true)
                setIsGettingLoading(false)
            }
        }
        get()
    }

    return (
        <>
            <MetaTags
                metaDescription="Anabul Hilang"
                metaTitle="Anabul Hilang"
                metaPath="/privacy"
                metaPreviewImage={metaPreviewImage}
            />
            <Header />

            <Container className={css.section}>
                <Row>
                    <Col>
                        <h1>Anabul Hilang</h1>

                        <p>


                        </p>

                        {
                            (isGettingLoading == false && isGettingDataReady && gettingData != undefined) ? (
                                <div className={css.list}>

                                    {
                                        gettingData.length < 1 ? (
                                            <Row>
                                                <Col>
                                                    <div className={css.notFound}>
                                                        <img src={NotFoundIllustration} alt={'Not Found Illustration'} />
                                                        <h1>Belum Ada Data Anabul Hilang</h1>
                                                        <h2>Kami akan terus memantau pembaharuan data.</h2>

                                                        <Link to={'/'}>
                                                            <Button>Kembali ke Beranda</Button>
                                                        </Link>
                                                    </div>


                                                </Col>
                                            </Row>
                                        ) : (
                                            <>

                                                {
                                                    gettingData.map((item: any) => {
                                                        return (
                                                            <a className={css.item} href={'/profile/?handle=' + item.anabul_handle}>
                                                                <div className={css.leftSide}>

                                                                    <div className={css.contactButton}>
                                                                        <span><FontAwesomeIcon icon={faPhone} /> Hubungi Pemilik</span>
                                                                    </div>
                                                                    <div className={css.photo}>
                                                                        <img src={item.pass_photo_original} alt="photo" />
                                                                    </div>
                                                                </div>
                                                                <div className={css.rightSide}>
                                                                    <div
                                                                        className={css.missingRibbon}
                                                                    >
                                                                        <div className={css.text}>SEDANG HILANG</div>
                                                                    </div>
                                                                    <div className={css.name}>
                                                                        {item.anabul_name}
                                                                        {
                                                                            item.is_verified ? (
                                                                                <div className={css.verifiedBadge}></div>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                    <div className={css.missingDate}>
                                                                        Hilang Pada: <Moment format="D MMMM YYYY">{Number(item.missing_mode_started_at) * 1000}</Moment>
                                                                    </div>
                                                                    <div className={css.species}>
                                                                        Spesies: {item.animal_type_label}
                                                                    </div>
                                                                    <div className={css.sex}>
                                                                        Jenis Kelamin: {item.sex_label}
                                                                    </div>
                                                                    <div className={css.breeds}>
                                                                        Ras: {item.breeds.label}
                                                                    </div>

                                                                    <div className={css.regency}>
                                                                        <div className={css.iconMarker} />
                                                                        {item.place_of_birth_label}
                                                                    </div>
                                                                </div>


                                                            </a>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            ) : (
                                <>
                                    <div className={css.list}>
                                        <LoadingDefault />
                                    </div>
                                </>
                            )

                        }
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default MissingPets
