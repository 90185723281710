import * as React from "react"
import ContentLoader from "react-content-loader"
import * as css from "./LoadingDefault.module.css"

const LoadingDefault = () => {
    return (
        <>
            <div className={css.desktopView}>
                <ContentLoader viewBox="0 0 1000 1000" backgroundColor="#f5f5f5" foregroundColor="#dfdfdf">
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="312" />
                    <rect x="0" y="324" rx="10" ry="10" width="100%" height="312" />
                </ContentLoader>
            </div>
            <div className={css.mobileView}>
                <ContentLoader viewBox="0 0 1000 2000" backgroundColor="#f5f5f5" foregroundColor="#dfdfdf">
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="530" />
                    <rect x="0" y="560" rx="10" ry="10" width="100%" height="530" />
                </ContentLoader>
            </div>
        </>
    )
}

export default LoadingDefault
